import Component from 'vue-class-component';
import PageRender from '@/models/PageRender';
import { dealsService, invoiceService, organizationsServiceCRM, productsServiceCRM, teamHelper, templateService } from '@/main';
import Deal from '@/models/CRM/Deal';
import { DealLine } from '@/models/CRM/DealLine';
import { required, requiredIf, minValue } from 'vuelidate/lib/validators';
import Organization from '@/models/CRM/Organization';
import Contact from '@/models/CRM/Contact';
import Product, { ProductActivity } from '@/models/CRM/Product';
import Vue from 'vue';
import to from 'await-to-js';
import { Prop } from 'vue-property-decorator';
import { Person } from '@/models/Person';
import { profileModule } from '@/store/modules/profile';
import moment from 'moment';
import { Template } from '@/models/CRM/Template';
import DealToInvoiceModalComponent from '@/components/modals/deal-to-invoice-modal.vue';
import { orderBy, SortDescriptor } from '@progress/kendo-data-query';
import { settingsModule } from '@/store/modules/settings';
import { translateModule } from '@/store/modules/translate';
import { PaymentStatusEnum } from '@/models/PaymentStatus';
import sendInvoiceModalVue from '@/components/invoice/send-invoice-modal.vue';
import StructureTeam from '@/models/Ecosystem/StructureTeam';
import Attachment from '@/models/Attachment';
import RemindDealComponent from '@/components/modals/send-reminder-deal-modal';

const validNumber = (value: any): boolean => {
    if (typeof value === 'undefined') {
        return false;
    }

    return value.replace(/\D/g, '') < 2147483647;
};
@Component({
    validations: {
        deal: {
            name: { required },
            organization: { required },
            contact: { required },
            invoiceNumber: { validNumber },
            lines: {
                $each: {
                    amount: { required },
                    price: { required },
                    product: {
                        requiredIf: requiredIf((form) => {
                            return form.description === null;
                        }),
                        productId: { minValue: minValue(1) },
                    },
                    description: {
                        requiredIf: requiredIf((form) => {
                            return form.product === null;
                        }),
                    },
                    departmentObj: { required },
                    projectObj: { required },
                },
            },
        },
    },
    components: {
        SendInvoiceModal: sendInvoiceModalVue,
    },
} as any)
export default class CreateDealComponent extends PageRender {
    @Prop({ default: () => new Deal() }) public deal: Deal;
    public organizations: Organization[] = [];
    public contacts: Contact[] = [];
    public isEdit: boolean = false;
    public allowedToEdit: boolean = true;
    public organizationSelected: number = 0;
    public contactSelected: number = 0;
    public tableRerender: number = 0;
    public selectedLanguage: string = 'NL';
    public templates: Template[] = [];
    //TODO implement type
    public noteChanged: boolean = false;
    public appliedTaxRate: any = null;
    public isSplitted: boolean = false;
    public selectedAttachment = null;

    public availableCurrencies = [
        { text: 'Euro', value: 'EUR' },
        { text: 'US Dollar', value: 'USD' },
        { text: 'Pound sterling', value: 'GBP' },
        { text: 'Saudi riyal', value: 'SAR' },
        { text: 'Swiss franc', value: 'CHF' },
    ];
    public selectedCurrency = { text: 'Euro', value: 'EUR' };
    public selectedTemplate: Template = null;
    public selectedOwner: { name: string; emailAddress: string } = null;
    public selectedTeam: StructureTeam;
    public teamChanged: boolean = false;
    public owners: { name: string; emailAddress: string }[] = [];
    public attachments: Attachment[] = [];
    public sort: SortDescriptor[] = [{ field: 'lineNumber', dir: 'asc' }];
    public $refs!: {
        setAsWonModal: DealToInvoiceModalComponent;
        remindDealModel: RemindDealComponent;
    };
    public dealStates: { id: number; name: string }[] = [
        { id: 4, name: 'Lead' },
        { id: 1, name: 'Open' },
        { id: 2, name: 'Won' },
        { id: 6, name: 'Needs to be invoiced yet' },
        { id: 7, name: 'Invoice sent' },
        { id: 3, name: 'Archive' },
    ];

    private products: Product[] = [];

    public get teamOptions() {
        const myTeams = teamHelper.teams;
        return this.structure.teams.filter((x) => myTeams.find((y) => y.id === x.teamId));
    }

    public get linkedInvoiceRoute() {
        return {
            name: 'invoice-sales',
            params: { legalEntity: this.deal.externalInvoice.legalEntityId, invoiceId: this.deal.externalInvoice.invoiceId },
        };
    }

    // public get canSendInvoice() {
    //     if (!this.deal.externalInvoice) {
    //         return false;
    //     }

    //     return this.deal.paymentStatus === PaymentStatusEnum.Open.toLowerCase() || this.deal.paymentStatus === PaymentStatusEnum.Full.toLowerCase();
    // }

    public get canSendReminder() {
        if (!this.deal.externalInvoice) {
            return false;
        }

        return this.deal.dealState.dealStateId === 7 && this.deal.paymentStatus === PaymentStatusEnum.Open.toLowerCase();
    }

    public async created() {
        this.isEdit = this.deal && this.deal.id > 0;
        this.allowedToEdit = !(this.deal.dealState && this.deal.dealState.dealStateId === 7);

        await Promise.all([
            this.loadStructure(this.deal.invoiceDate),
            this.loadOwners(),
            this.loadTaxRates(),
            this.loadOrganizations(),
            this.loadProducts(),
            this.loadTemplates(),
        ]);

        this.selectedTeam = this.structure.teams.find((y) => y.teamId === teamHelper.getTeamId());

        if (this.isEdit) {
            this.deal.lines.forEach((line) => {
                if (line.department) {
                    line.departmentObj = this.structure.teams.find((y) => y.name.toLowerCase() === line.department.toLowerCase());
                }

                if (line.department && line.departmentObj && line.project) {
                    line.projectObj = line.departmentObj.groups.find((y) => y.name.toLowerCase() === line.project.toLowerCase());
                }

                if (line.taxRateId) {
                    line.taxRate = this.taxRates.find((y) => y.id === line.taxRateId);
                }

                line.products = [...this.products];
            });

            for (let i = 0; i < this.orderedLines.length; i++) {
                const line = this.orderedLines[i];

                if (!line.lineNumber) {
                    line.lineNumber = i + 1;
                }
            }

            this.selectedCurrency = this.availableCurrencies.find((x) => x.value === this.deal.currency.toUpperCase());
            this.selectTemplate(this.templates.find((x) => x.invoiceTemplateId === this.deal.template));

            await this.loadAttachments();
            const organization = await organizationsServiceCRM.getOrganization((this.deal.organization as Organization).organizationId);
            this.contacts = organization.contacts;

            if (organization && organization.countryIso2) {
                this.appliedTaxRate = await organizationsServiceCRM.getTaxRate((this.deal.organization as Organization).organizationId);
            }
        } else {
            let teamSettings = settingsModule.findTeamFinanceSettings(teamHelper.currentTeamId);
            if (!teamSettings) {
                await settingsModule.fetchTeamSettingsIfNeeded(teamHelper.currentTeamId);
                teamSettings = settingsModule.findTeamFinanceSettings(teamHelper.currentTeamId);
            }

            this.selectCurrency(this.availableCurrencies.find((x) => x.value === teamSettings.defaultCurrency));
        }

        this.mapOwner();
        if (this.deal.id > 0 && this.orderedLines.length === 0) {
            this.addLineToDeal();
        }
        this.isLoading = false;
    }

    public async loadOwners() {
        const owners = await teamHelper.getTeamMembers(teamHelper.currentTeamId);

        this.owners = owners
            .filter((x) => x.emailAddress)
            .map((x) => {
                return { emailAddress: x.emailAddress, name: `${x.firstName} ${x.lastName}` };
            });
    }

    public async loadTemplates() {
        const legalEntityReference = teamHelper.getLegalEntityReference();
        this.templates = await templateService.getTemplates(legalEntityReference);
    }

    public selectCurrency(currency) {
        this.selectedCurrency = currency;
        this.deal.currency = currency.value;
    }

    public selectOwner(person: Person) {
        this.deal.owner = person.emailAddress;
    }

    public async selectTeam() {
        this.teamChanged = true;
    }

    public async loadOrganizations() {
        const organizations = await organizationsServiceCRM.getOrganizations();
        this.organizations = organizations.items;
    }

    public async loadAttachments() {
        const attachments = await dealsService.getAttachments(this.deal.id);
        this.attachments = attachments;
    }

    public async downloadAttachment(attachment) {
        const link = document.createElement('a');
        link.href = attachment.url;
        link.setAttribute('download', `${attachment.fileName}`);
        document.body.appendChild(link);
        return link.click();
    }

    public async detachAttachment(attachment) {
        const answer = await this.$bvModal.msgBoxConfirm(translateModule.value('REMOVE_ATTACHMENT_CONFIRM_TEXT'), {
            title: translateModule.value('REMOVE_ATTACHMENT_CONFIRM_TITLE'),
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'warning',
            okTitle: translateModule.value('YES'),
            cancelTitle: translateModule.value('NO'),
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true,
        });

        if (answer) {
            this.showPending('REMOVE_ATTACHMENT_PENDING');
            const [err] = await to(dealsService.deleteAttachment(this.deal.id, attachment.fileId));

            if (err) {
                this.clearAndShowError('REMOVE_ATTACHMENT_FAILED', err);
                return;
            }

            this.attachments = this.attachments.filter((x) => x.fileId !== attachment.fileId);
            this.clearAndShowSuccess('REMOVE_ATTACHMENT_SUCCESS');
        }
    }

    public async loadProducts() {
        const products = await productsServiceCRM.getProducts();
        this.products = [...products.items.map((x) => new Product(x))];
    }

    public createOrganizationSideAction() {
        this.$sideActions.push('create-organization-side-action', { data: true, existingOrganizations: this.organizations }, (result) => {
            this.setOrganization(result);
        });
    }

    public setOrganization(result) {
        Vue.set(this.deal, 'organization', result);
        this.organizationSelected++;
    }

    public createContactSideAction() {
        this.$sideActions.push('create-contact-side-action', { organization: this.deal.organization }, (result) => {
            this.setContact(result);
        });
    }

    public showMailLogSideAction() {
        this.$sideActions.push('mail-log-side-action', { invoiceId: this.deal.externalInvoice.invoiceId });
    }

    public async toggleLine(line) {
        line.open = !line.open;
    }

    public async selectProduct(product: Product, dealLineIndex) {
        if (!this.orderedLines[dealLineIndex].price || this.orderedLines[dealLineIndex].price === 0) {
            this.orderedLines[dealLineIndex].price = product.price;
        }
        this.orderedLines[dealLineIndex].product = product;
        const [err, response] = await to(productsServiceCRM.getProductActivities(product.productId));
        this.orderedLines[dealLineIndex].product.connectedActivities = response.data;
    }

    public async selectActivity(activity: ProductActivity, dealLineIndex: number) {
        this.orderedLines[dealLineIndex].department = activity.teamName;
        this.orderedLines[dealLineIndex].departmentObj = this.structure.teams.find((x) => x.name === activity.teamName);

        this.orderedLines[dealLineIndex].project = activity.activityName;
        this.orderedLines[dealLineIndex].projectObj = this.orderedLines[dealLineIndex].departmentObj.groups.find(
            (x) => x.name.toLowerCase() === activity.activityName.toLowerCase(),
        );
    }

    public selectDepartment(department, dealLineIndex) {
        this.orderedLines[dealLineIndex].department = department.name;
        this.orderedLines[dealLineIndex].project = null;
        this.orderedLines[dealLineIndex].projectObj = null;
        this.orderedLines[dealLineIndex].connectedActivity = 'manual'
    }

    public async selectProject(activity, dealLineIndex) {
        this.orderedLines[dealLineIndex].project = activity.name;
        const teamName = this.orderedLines[dealLineIndex].department;
        const connectedActivityProducts = await productsServiceCRM.getProductActivities(null, teamName, activity.name);

        const connectedProducts = this.products.filter((x) => connectedActivityProducts.data.find((y) => y.productId === x.productId));
        const notConnectedProducts = this.products.filter((x) => !connectedProducts.find((y) => y.productId === x.productId));

        this.orderedLines[dealLineIndex].products = [
            ...connectedProducts,
            { $isDisabled: true, name: '--- Not connected products ---', productId: -1 } as any,
            ...notConnectedProducts,
        ];

        if (connectedProducts.length === 1 && this.orderedLines[dealLineIndex].product === null) {
            this.selectProduct(connectedProducts[0], dealLineIndex);
            this.orderedLines[dealLineIndex].connectedActivity = connectedActivityProducts.data.find(x => x.productId === connectedProducts[0].productId);
        }

        this.tableRerender++;
    }

    public updateTotalsKey() {
        this.tableRerender++;
    }

    public async connectActivity(dealLineIndex: number) {
        const productId = this.orderedLines[dealLineIndex].product.productId;

        const activity = new ProductActivity({
            activityName: this.orderedLines[dealLineIndex].project,
            activityObj: this.orderedLines[dealLineIndex].projectObj,
            teamObj: this.orderedLines[dealLineIndex].departmentObj,
            teamName: this.orderedLines[dealLineIndex].department,
        });
        const [err] = await to(productsServiceCRM.saveConnectedActivity(productId, activity));
        if (err) {
            return this.clearAndShowError('CONNECT_CONNECTED_ACTIVITY_FAILED', err);
        }

        this.orderedLines[dealLineIndex].product.connectedActivities.push(activity);
        this.orderedLines[dealLineIndex].connectedActivity = activity;
    }

    public clearActivity(dealLineIndex: number) {
        this.orderedLines[dealLineIndex].project = null;
        this.orderedLines[dealLineIndex].projectObj = null;
        this.orderedLines[dealLineIndex].department = null;
        this.orderedLines[dealLineIndex].departmentObj = null;
    }

    public dealToWon(canceled: boolean) {
        if (!canceled) {
            this.deal.dealState.name = 'Invoice sent';
            this.deal.dealState.dealStateId = 7;
            this.allowedToEdit = false;
        }
    }

    public async createDealAndAnother() {
        await this.createDeal(false, false);
        if (!this.deal.id) {
            return Promise.reject(new Error('Deal not created'));
        }
        await this.$router.push({ name: 'crm-create-deal' });

        return;
    }

    public async createDealAndGoToOverview() {
        await this.createDeal(false, false);
        localStorage.setItem('crm-deal-created', this.deal.id.toString());
        await this.$router.push({ name: 'crm-deals' });

        return;
    }

    public async createDeal(asLead: boolean = false, redirect: boolean = true) {
        this.submitted = true;
        if (!asLead && (this as any).$v.$invalid) {
            this.showWarning('CREATE_DEAL_INVALID');
            for (let i = 0; i < this.deal.lines.length; i++) {
                const line = this.deal.lines[i];
                const line2 = (this as any).$v.deal.lines.$each[this.findOrderLineIndex(line.lineNumber)];

                line.open = line2.$invalid;
            }
            return;
        }

        this.showPending('CREATE_DEAL_PENDING');

        const taxRate = await organizationsServiceCRM.getTaxRate((this.deal.organization as Organization).organizationId);

        for (let i = 0; i < this.orderedLines.length; i++) {
            const line = this.orderedLines[i];
            line.taxRateId = taxRate ? taxRate.taxRate : null;
            line.lineNumber = i + 1;
        }

        if (this.teamChanged) {
            this.deal.teamId = this.selectedTeam.teamId;
        }

        if (this.selectedTemplate && this.selectedTemplate.invoiceTemplateId) {
            this.deal.template = this.selectedTemplate.invoiceTemplateId;
        }

        const [err, response] = await to(dealsService.createDeal(this.deal));
        if (err) {
            return this.clearAndShowError('CREATE_DEAL_FAILED', err);
        }

        const deal = new Deal(response.data);
        this.deal.id = deal.id;
        this.clearNotifications();

        if (!asLead && redirect) {
            const params = { dealId: deal.id.toString(), teamKey: this.selectedTeam.teamKey, teamId: this.selectedTeam.teamId.toString() };

            if (this.teamChanged) {
                teamHelper.setTeamByTeamId(this.selectedTeam.teamId);
            }

            await this.$router.push({ name: 'crm-deal', params });
        }
    }

    public async createLead() {
        this.submitted = true;
        if ((this as any).$v.$invalid) {
            this.clearAndShowWarning(`DEAL_LEAD_INVALID`);
            return;
        }

        await this.createDeal(true);
        await this.setDealAsLead();
    }

    public async updateDeal() {
        this.submitted = true;
        if ((this as any).$v.$invalid) {
            this.showWarning('UPDATE_DEAL_INVALID');
            for (let i = 0; i < this.deal.lines.length; i++) {
                const line = this.deal.lines[i];
                const line2 = (this as any).$v.deal.lines.$each[this.findOrderLineIndex(line.lineNumber)];

                line.open = line2.$invalid;
            }
            return;
        }

        const taxRate = await organizationsServiceCRM.getTaxRate((this.deal.organization as Organization).organizationId);

        for (let i = 0; i < this.orderedLines.length; i++) {
            const line = this.orderedLines[i];

            line.taxRateId = taxRate ? taxRate.taxRate : null;
            line.lineNumber = i + 1;
        }

        if (this.deal.invoiceDate) {
            this.deal.invoiceDate = moment(this.deal.invoiceDate, 'YYYY-MM-DD').format('YYYY-MM-DDT00:00:00[Z]');
        }

        if (this.teamChanged) {
            this.deal.teamId = this.selectedTeam.teamId;
        }

        if (this.selectedTemplate && this.selectedTemplate.invoiceTemplateId) {
            this.deal.template = this.selectedTemplate.invoiceTemplateId;
        }

        this.showPending('UPDATE_DEAL_PENDING');
        const [err] = await to(dealsService.updateDeal(this.deal));
        if (err) {
            return this.clearAndShowError('UPDATE_DEAL_FAILED', err);
        }

        await this.checkTeamChange();
        this.clearAndShowSuccess('UPDATE_DEAL_SUCCESS');
    }

    public async checkTeamChange() {
        if (this.teamChanged) {
            const [err] = await to(dealsService.moveDeal(this.deal.id, this.selectedTeam.teamId));
            if (!err) {
                this.clearAndShowSuccess('UPDATE_DEAL_SUCCESS');

                teamHelper.setTeamByTeamId(this.selectedTeam.teamId);

                const route = this.$route;
                if (typeof route.params.teamId === 'undefined' && typeof route.params.teamKey === 'undefined') {
                    // no routing needed, team isn't part of route.
                    return;
                }

                const params = Object.assign({}, route.params);
                params.teamKey = this.selectedTeam.teamKey;
                params.teamId = this.selectedTeam.teamId.toString();

                return this.$router.push({
                    name: route.name,
                    params,
                });
            } else {
                return this.clearAndShowError('MOVE_DEAL_FAILED', err);
            }
        }
    }

    public async showSetAsWonModal() {
        await this.$refs.setAsWonModal.init(this.deal);
    }

    public hasAnyProducts(tax) {
        return this.orderedLines.filter((x) => (x.taxRate && x.taxRate.id === tax.id) || x.taxRateId === tax.id).length > 0;
    }

    public getTaxForType(tax) {
        const lines = this.orderedLines.filter((x) => (x.taxRate && x.taxRate.id === tax.id) || x.taxRateId === tax.id);

        if (lines.length > 0) {
            const total = lines.reduce((prev, cur) => {
                return prev + parseFloat(cur.amount.toString()) * cur.price;
            }, 0);

            return total * (tax.percentage / 100);
        }

        return 0;
    }

    public getTotalTax() {
        let taxesTotal = 0;
        if (this.appliedTaxRate) {
            const taxRate = this.taxRates.find((x) => x.id === this.appliedTaxRate.taxRate);
            taxesTotal += this.getTaxForType(taxRate);
        }

        return taxesTotal; // + this.getSubtotal();
    }
    public getSubtotal() {
        let subTotal = 0;
        this.orderedLines.forEach((line) => {
            subTotal += parseFloat(line.amount.toString()) * line.price;
        });

        return subTotal;
    }

    public getTotalAmount(line: DealLine) {
        return Vue.filter('numberFormat')(parseFloat(line.amount.toString()) * line.price);
    }

    public async setDealAsOpen() {
        this.submitted = true;
        if ((this as any).$v.$invalid) {
            this.showWarning('DEAL_OPEN_INVALID');
            for (let i = 0; i < this.deal.lines.length; i++) {
                const line = this.deal.lines[i];
                const line2 = (this as any).$v.deal.lines.$each[this.findOrderLineIndex(line.lineNumber)];

                line.open = line2.$invalid;
            }
            return;
        }

        this.showPending('DEAL_OPEN_PENDING');

        const [err] = await to(dealsService.changeDealState(this.deal.id, 1));
        if (err) {
            return this.clearAndShowError('DEAL_OPEN_FAILED', err);
        }

        this.deal.dealState.name = 'Open';
        this.clearAndShowSuccess('DEAL_OPEN_SUCCESS');
    }

    public async setDealToStatus(targetGroupId) {
        if ((this as any).$v.$invalid) {
            this.clearAndShowWarning(`DEAL_STATUS_CHANGE_INVALID`);
            for (let i = 0; i < this.deal.lines.length; i++) {
                const line = this.deal.lines[i];
                const line2 = (this as any).$v.deal.lines.$each[this.findOrderLineIndex(line.lineNumber)];

                line.open = line2.$invalid;
            }
            return false;
        }

        await this.updateDeal();

        this.showPending('DEAL_STATUS_CHANGE');

        const [err] = await to(dealsService.changeDealState(this.deal.id, targetGroupId));
        if (err) {
            return this.clearAndShowError('DEAL_STATUS_CHANGE_FAILED', err);
        }

        this.deal.dealState.name = this.dealStates.find((x) => x.id === targetGroupId).name;
        this.deal.dealState.dealStateId = this.dealStates.find((x) => x.id === targetGroupId).id;
        this.clearAndShowSuccess('DEAL_STATUS_CHANGE_SUCCESS');

        return true;
    }

    public setContact(result) {
        Vue.set(this.deal, 'contact', result.contact);
        this.contactSelected++;
    }

    public async dealOrganizationSelected(selectedOrganization: Organization) {
        const organization = await organizationsServiceCRM.getOrganization(selectedOrganization.organizationId);
        this.contacts = organization.contacts;
        if (!this.deal.template) {
            this.selectTemplate(this.templates.find((x) => x.invoiceTemplateId === organization.template));

            if (!this.selectedTemplate) {
                this.selectTemplate(this.templates.find((x) => x.name.indexOf(organization.name) > -1));
            }
        }

        if (organization && organization.countryIso2) {
            this.appliedTaxRate = await organizationsServiceCRM.getTaxRate((this.deal.organization as Organization).organizationId);
        }
    }

    public addLineToDeal() {
        if (this.orderedLines.length > 0) {
            const line = Object.assign({}, this.orderedLines[this.orderedLines.length - 1]);
            line.open = true;
            line.lineNumber++;

            this.deal.lines.push(line);
        } else {
            const newLine = new DealLine();
            newLine.open = true;
            newLine.departmentObj = this.structure.teams.find((x) => x.teamId === teamHelper.currentTeam.id);
            newLine.department = newLine.departmentObj.name;
            newLine.lineNumber = 1;
            newLine.products = [...this.products];

            this.deal.lines.push(newLine);
        }
    }

    public async saveNote() {
        await this.updateDeal();
        this.noteChanged = false;
    }

    public lineUp(index: number) {
        const lineUp = this.orderedLines[index];
        const lineDown = this.orderedLines[index - 1];

        lineUp.lineNumber--;
        lineDown.lineNumber++;
    }

    public lineDown(index: number) {
        const lineDown = this.orderedLines[index];
        const lineUp = this.orderedLines[index + 1];

        lineUp.lineNumber--;
        lineDown.lineNumber++;
    }

    public findOrderLineIndex(lineNumber: number) {
        return this.deal.lines.findIndex((x) => x.lineNumber === lineNumber);
    }

    public removeDealLine(lineNumber: number) {
        this.deal.lines.splice(this.findOrderLineIndex(lineNumber), 1);
    }

    public async copyDeal() {
        const [err, response] = await to(dealsService.cloneDeal(this.deal));
        if (err) {
            return this.clearAndShowError('CLONE_DEAL_FAILED', err);
        }

        this.clearAndShowSuccess('CLONE_DEAL_SUCCESS');
        const deal = new Deal(response.data);
        await this.$router.push({ name: 'crm-deal', params: { dealId: deal.id.toString() } });
    }

    public getActivities(line) {
        if (line.departmentObj) {
            return this.structure.teams.find((x) => x.teamId === line.departmentObj.teamId).groups;
        }

        return [];
    }

    public async setDealAsLead() {
        this.showPending('DEAL_LEAD_PENDING');

        const [err] = await to(dealsService.changeDealState(this.deal.id, 4));
        if (err) {
            return this.clearAndShowError('DEAL_LEAD_FAILED', err);
        }

        this.deal.dealState.name = 'Lead';
        this.clearAndShowSuccess('DEAL_LEAD_SUCCESS');

        await this.$router.push({ name: 'crm-deal', params: { dealId: this.deal.id.toString() } });
    }

    public get teams() {
        return teamHelper.teams;
    }
    public get orderedLines() {
        return orderBy(this.deal.lines, this.sort);
    }

    public exportDeal() {
        const csv = [];
        const headers = ['Item', 'Price', 'Amount', 'Total'];
        csv.push(headers.join(';'));

        for (let i = 0; i < this.orderedLines.length; i++) {
            const row = [];
            const line = this.orderedLines[i];

            const description = line.description ? ` - ${line.description.replace(/(\r\n|\n|\r)/gm, '').replace(/(\s\s)/gm, ' ')}` : '';

            let data =
                line.projectObj != null
                    ? `${line.projectObj.name}${description}`.replace(/(\r\n|\n|\r)/gm, '').replace(/(\s\s)/gm, ' ')
                    : description;
            data = data.replace(/"/g, '""');

            row.push(`"${data}"`);

            const price = line.price;
            row.push(`"${price}"`);

            const amount = line.amount;
            row.push(`"${amount}"`);

            const totalPrice = line.price * parseFloat(line.amount.toString());
            row.push(`"${totalPrice}"`);

            csv.push(row.join(';'));
        }

        csv.push('\n');
        csv.push(['\t', '\t', 'Total', this.getSubtotal()].join(';'));

        const csvString = csv.join('\n');
        // Download it
        const filename = 'export_' + this.deal.name + '_' + new Date().toLocaleDateString() + '.csv';
        const link = document.createElement('a');
        link.style.display = 'none';
        link.setAttribute('target', '_blank');
        link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvString));
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    public previewAttachment(attachment) {
        this.showPending('LOAD_BLOB');
        this.selectedAttachment = attachment;
        this.isSplitted = true;
        if (window.innerWidth < 2000) {
            this.$sideActions.push('preview-attachment-action', { attachment });
        }
    }

    public selectTemplate(template: Template) {
        if (!template) {
            return;
        }
        this.selectedTemplate = template;
        this.deal.template = template.invoiceTemplateId;
    }

    private mapOwner() {
        if (this.deal.owner) {
            const owner = this.owners.find((x) => x.emailAddress === this.deal.owner);
            if (owner) {
                this.selectedOwner = owner;
            } else {
                this.selectedOwner = { emailAddress: this.deal.owner, name: `${this.deal.owner} (not present in current team)` };
            }
        } else {
            this.deal.owner = profileModule.brightProfile.emailAddress;
            const owner = this.owners.find((x) => x.emailAddress === this.deal.owner);
            if (owner) {
                this.selectedOwner = owner;
            } else {
                this.selectedOwner = { emailAddress: this.deal.owner, name: `${this.deal.owner} (not present in current team)` };
            }
        }
    }
}
