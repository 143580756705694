import Component from 'vue-class-component';
import PageRender from '@/models/PageRender';
import { countryService, organizationsServicePurchases, teamHelper } from '@/main';
import { required } from 'vuelidate/lib/validators';
import Organization from '@/models/Purchases/Organization';
import to from 'await-to-js';
import { Prop } from 'vue-property-decorator';
import { purchaseSettingsModule } from '@/store/modules/purchaseSettings';
import IBAN from 'iban';
import COCOrg from '@/models/Organizations/COCOrganization';

const validIban = (value) => !value || value.length === 0 || IBAN.isValid(value);
@Component({
    validations: {
        organization: {
            name: { required },
            country: { required },
            iban: { validIban },
        },
    },
} as any)
export default class CreateOrEditOrganizationComponent extends PageRender {
    @Prop({ default: () => new Organization() }) public organization: Organization;
    @Prop({ default: () => [] }) public organizations: Organization[];

    public isEdit: boolean = false;
    public selectedCountryIso2: any = { id: 'NL', name: 'Netherlands' };
    public hits: any[] = [];
    public reload: number = 0;

    public get otherOrganizations() {
        return this.organizations.filter((org) => org.organizationId !== this.organization.organizationId);
    }

    public get nameMatch() {
        return this.otherOrganizations.find(
            (org) => this.organization.name.length && org.name.toLowerCase() === this.organization.name.toLowerCase(),
        );
    }

    public get cocExist() {
        let org = null;
        if (this.organization.coCNumber && this.organization.coCNumber.length) {
            org = this.otherOrganizations.find((org) => this.organization.coCNumber.length && org.coCNumber === this.organization.coCNumber);
        }
        return org;
    }

    public async created() {
        this.isEdit = this.organization && this.organization.organizationId > 0;

        if (this.isEdit) {
            const country = this.countries.find((x) => x.name === this.organization.country);
            if (country) {
                this.selectedCountryIso2 = country;
            }
        } else {
            let teamSettings = purchaseSettingsModule.findTeamFinanceSettings(teamHelper.currentTeamId);
            if (!teamSettings) {
                await purchaseSettingsModule.fetchTeamSettingsIfNeeded(teamHelper.currentTeamId);
                teamSettings = purchaseSettingsModule.findTeamFinanceSettings(teamHelper.currentTeamId);
            }
            this.selectedCountryIso2 = this.countries.find((x) => x.id === teamSettings.defaultCountry);
        }

        if (!this.organizations.length) {
            this.organizations = (await organizationsServicePurchases.getOrganizations()).items;
        }

        this.selectCountry(this.selectedCountryIso2);
    }

    public async mounted() {
        this.isLoading = false;
    }

    public resetOrganization() {
        this.organization = new Organization();
    }

    public async createOrganization() {
        this.submitted = true;

        if ((this as any).$v.$invalid) {
            this.showWarning(`CREATE_ORGANIZATION_INVALID`);
            return;
        }

        if (this.cocExist) {
            return this.showError('ORGANIZATION_COC_EXISTS');
        }

        this.showPending('CREATE_ORGANIZATION_PENDING');
        const [err, response] = await to(organizationsServicePurchases.createOrganization(this.organization));
        if (err) {
            return this.clearAndShowError('CREATE_ORGANIZATION_FAILED', err);
        }

        const organization = new Organization(response.data);
        this.$emit('finished', organization);
        this.clearNotifications();
    }

    public async updateOrganization() {
        this.submitted = true;
        if ((this as any).$v.$invalid) {
            this.showWarning(`UPDATE_ORGANIZATION_INVALID`);
            return;
        }

        this.showPending('UPDATE_ORGANIZATION_PENDING');
        const [err] = await to(organizationsServicePurchases.updateOrganization(this.organization));
        if (err) {
            return this.clearAndShowError('UPDATE_ORGANIZATION_FAILED', err);
        }

        this.clearNotifications();
        await this.$router.push({ name: 'purchase-organization', params: { dealId: this.organization.organizationId.toString() } });
    }

    public selectCountry(country) {
        this.selectedCountryIso2 = country;
        this.organization.countryIso2 = country.id;
        this.organization.country = country.name;

        const countries = countryService.getCountries();
        this.organization.exEu = countries[0][this.selectedCountryIso2.id].continent !== 'EU';
    }

    public get countries() {
        const countries = countryService.getCountries();
        const list = Object.keys(countries[0]).map((x) => {
            return {
                id: x,
                name: countries[0][x].name,
            };
        });

        return list;
    }

    public async search(query) {
        if (!query || query.length < 5) {
            return this.showWarning('SEARCH_QUERY_EMPTY_OR_TOO_SHORT');
        }

        this.hits = await organizationsServicePurchases.suggetCOCData(query);
        if (this.hits) {
            this.$sideActions.push('coc-data-suggest-result-side-action', { hits: this.hits }, async (organization: COCOrg) => {
                this.organization.name = organization.handelsnaam;
                this.organization.vatNumber = organization.BTW;
                this.organization.city = organization.plaats;
                this.organization.zipCode = organization.postcode;
                this.organization.addressLineOne = organization.straat + ' ' + organization.huisnummer;
                this.organization.zipCode = organization.postcode;
                this.organization.coCNumber = organization.dossiernummer;

                this.reload++;
            });
        } else {
            this.showWarning('COC_API_NO_RESULTS');
        }
    }
}
