export default class COCDataResponse {
    public dossiernummer: string = '';
    public handelsnaam: string = '';
    public id: string = '';
    public link: string = '';
    public text: string = '';

    constructor(data?: Partial<COCDataResponse>) {
        if (data) {
            Object.assign(this, data);
        }
    }
}
