import Vue from 'vue';
import { BaseService } from './baseService';
import Range from '@/models/Finance/Api/Range';
import { to } from 'await-to-js';
import Organization from '@/models/CRM/Organization';
import Contact from '@/models/CRM/Contact';
import COCDataResponse from '@/models/Organizations/COCDataResponse';
import COCOrg from '@/models/Organizations/COCOrganization';
import Attachment from '@/models/Attachment';

export default class OrganizationsServiceCRM extends BaseService {
    private endpoint = `${Vue.$env().crmApiEndpoint}organizations`;

    public async getOrganizations(): Promise<Range<Organization>> {
        const [err, response] = await to(this.get(`${this.endpoint}`));

        if (err) {
            this.clearAndShowError(`LOAD_ORGANIZATIONS_FAILED`, err);
            return new Range<Organization>();
        }

        return new Range<Organization>(response.data);
    }

    public async getOrganization(organizationId: number) {
        const [err, response] = await to(this.get(`${this.endpoint}/:organizationId`, [['organizationId', organizationId]]));

        if (err) {
            this.clearAndShowError(`LOAD_ORGANIZATION_FAILED`, err);
            return new Organization();
        }

        return new Organization(response.data);
    }

    public async getContacts(organizationId: number): Promise<Range<Contact>> {
        const [err, response] = await to(this.get(`${this.endpoint}/:organizationId/contacts`, [['organizationId', organizationId]]));

        if (err) {
            this.clearAndShowError(`LOAD_CONTACTS_FAILED`, err);
            return new Range<Contact>();
        }

        return new Range<Contact>(response.data);
    }

    public async getTaxRate(organizationId: number): Promise<any> {
        const [err, response] = await to(this.get(`${this.endpoint}/:organizationId/tax-rate`, [['organizationId', organizationId]]));
        if (err) {
            return {};
        }

        return response.data;
    }

    public async coupleContact(organizationId: number, contact: Contact, contactType: string = 'Default'): Promise<boolean> {
        const [err] = await to(
            this.post(`${this.endpoint}/:organizationId/contacts`, { contactId: contact.contactId, organizationId, contactType }, [
                ['organizationId', organizationId],
            ]),
        );
        if (err) {
            this.clearAndShowError(`CREATE_CONTACT_FAILED`, err);
            return false;
        }

        return true;
    }

    public async decoupleContact(organizationId: number, contact: Contact): Promise<boolean> {
        const [err] = await to(this.delete(`${this.endpoint}/:organizationId/contacts/${contact.contactId}`, [['organizationId', organizationId]]));
        if (err) {
            this.clearAndShowError(`CREATE_CONTACT_FAILED`, err);
            return false;
        }

        return true;
    }

    public async archiveContact(organizationId: number, contact: Contact) {
        return this.delete(`${this.endpoint}/:organizationId/contacts/:contactId`, [
            ['organizationId', organizationId],
            ['contactId', contact.contactId],
        ]);
    }

    public createOrganization(organization: Organization) {
        return this.post(this.endpoint, organization);
    }

    public updateOrganization(organization: Organization) {
        return this.put(`${this.endpoint}/${organization.organizationId}`, organization);
    }

    public archiveOrganization(organization: Organization) {
        return this.delete(`${this.endpoint}/${organization.organizationId}`);
    }

    public async suggetCOCData(search: string) {
        const [err, response] = await to(
            this.get(`https://api.overheid.io/suggest/openkvk/${search}?size=10&fields[]=handelsnaam`, null, {
                headers: {
                    'ovio-api-key': '7b381ff5b39e220643595256f516d8f06fbb54811d1ccfa33a709a041da8ad68',
                },
            }),
        );
        if (err) {
            return this.clearAndShowError('COC_API_ERROR', err);
        }

        if (response.data.dossiernummer) {
            return response.data.dossiernummer.map((x) => new COCDataResponse(x));
        }

        return response.data.handelsnaam.map((x) => new COCDataResponse(x));
    }

    public async getCOCdata(id: string) {
        const [err, response] = await to(
            this.get(`https://api.overheid.io/openkvk/${id}`, null, {
                headers: {
                    'ovio-api-key': '7b381ff5b39e220643595256f516d8f06fbb54811d1ccfa33a709a041da8ad68',
                },
            }),
        );
        if (err) {
            return this.clearAndShowError('COC_API_ERROR', err);
        }

        return new COCOrg(response.data);
    }

    public async getAttachments(organizationId: number): Promise<Attachment[]> {
        const [err, response] = await to(this.get(`${this.endpoint}/${organizationId}/attachments`));

        if (err) {
            this.clearAndShowError(`LOAD_CONTACTS_FAILED`, err);
            return [];
        }

        return response.data.map((x) => new Attachment(x));
    }

    public async deleteAttachment(organizationId: number, fileId: string) {
        return this.delete(`${this.endpoint}/${organizationId}/attachments/${fileId}`);
    }
}
