export default class COCOrg {
    public BTW: string = '';
    public bestaandehandelsnaam: string[] = [];
    public handelsnaam: string = '';
    public plaats: string = '';
    public postcode: string = '';
    public straat: string = '';
    public huisnummer: string = '';
    public dossiernummer: string = '';

    constructor(data?: Partial<COCOrg>) {
        if (data) {
            Object.assign(this, data);
        }
    }
}
