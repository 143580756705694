import Component from 'vue-class-component';
import PageRender from '@/models/PageRender';
import { contactsServiceCRM } from '@/main';
import Vue from 'vue';
import to from 'await-to-js';
import Contact from '@/models/CRM/Contact';
import { orderBy, SortDescriptor } from '@progress/kendo-data-query';
import GridWrapperSearch from '@prd/shared-ui/src/components/Grid/models/GridWrapperSearch';
import { GridColumnProps } from '@progress/kendo-vue-grid';

@Component
export default class ContactsComponent extends PageRender {
    public contacts: Contact[] = [];
    public allContacts: Contact[] = [];
    public contactColumns: GridColumnProps[] = [
        { field: 'contactId', title: 'Id', width: 125, editable: false },
        { field: 'firstName', title: 'First name', editable: false },
        { field: 'lastName', title: 'Last name', editable: false },
        { cell: this.renderActions, title: 'Actions', width: 100, sortable: false },
    ];

    public sort: SortDescriptor[] = [{ field: 'lastName', dir: 'asc' }];

    public search: GridWrapperSearch = new GridWrapperSearch({
        properties: ['firstName', 'lastName'],
    });

    public async mounted() {
        await this.loadContacts();
        this.isLoading = false;
    }

    public async loadContacts() {
        const contacts = await contactsServiceCRM.getContacts();
        this.allContacts = this.contacts = orderBy(contacts, this.sort);
    }

    public createContactSideAction() {
        this.$sideActions.push('create-contact-side-action', { data: true }, (result) => {
            this.setContact(result);
        });
    }

    public setContact(result) {
        this.contacts.push(result.contact);
    }

    public async goToContact(e) {
        const contactId = e.dataItem.contactId;
        await this.$router.push({ name: 'crm-contact', params: { contactId } });
    }

    public renderActions(h, _, row) {
        const actions = [
            {
                title: 'Archive contact',
                function: this.archiveOrganization,
            },
        ];

        const item = row.dataItem;
        const props = { item, actions };

        return h(Vue.component('grid-actions'), { props });
    }

    public async archiveOrganization(item: Contact) {
        this.showPending('ARCHIVE_CONTACT_PENDING');
        const [err] = await to(contactsServiceCRM.archiveContact(item));
        if (err) {
            this.clearAndShowError('ARCHIVE_CONTACT_FAILED', err);
        }

        this.clearAndShowSuccess('ARCHIVE_CONTACT_SUCCESS');

        this.contacts = this.contacts.filter((x) => x.contactId !== item.contactId);
    }
}
