import Vue from 'vue';
import { BaseService } from './baseService';
import { to } from 'await-to-js';
import Contact from '@/models/CRM/Contact';

export default class ContactsServiceCRM extends BaseService {
    private endpoint = `${Vue.$env().crmApiEndpoint}contacts`;

    public async getContacts(): Promise<Contact[]> {
        const [err, response] = await to(this.get(`${this.endpoint}`));

        if (err) {
            this.clearAndShowError(`LOAD_ORGANIZATIONS_FAILED`, err);
            return [];
        }

        return response.data.map((x) => new Contact(x));
    }

    public async getContact(contactId: number) {
        const [err, response] = await to(this.get(`${this.endpoint}/:contactId`, [['contactId', contactId]]));

        if (err) {
            this.clearAndShowError(`LOAD_CONTACT_FAILED`, err);
            return new Contact();
        }

        return new Contact(response.data);
    }

    public createContact(contact: Contact) {
        return this.post(this.endpoint, contact);
    }

    public updateContact(contact: Contact) {
        return this.put(`${this.endpoint}/${contact.contactId}`, contact);
    }

    public archiveContact(contact: Contact) {
        return this.delete(`${this.endpoint}/${contact.contactId}`);
    }
}
