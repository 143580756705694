import Contact from './Contact';

export default class Organization {
    public organizationId: number = null;
    public id: number = null;
    public name: string = '';
    public contacts: Contact[] = [];

    public coCNumber: string = '';
    public vatNumber: string = '';
    public addressLineOne: string = '';
    public addressLineTwo: string = '';
    public city: string = '';
    public zipCode: string = '';
    public state: string = '';
    public country: string = '';
    public countryIso2: string = '';
    public preferredLanguage: string = '';
    public exEu: boolean;
    public template?: number;
    public score: number;
    public aliases?: string[] = [];

    constructor(obj?: Partial<Organization>) {
        if (obj) {
            Object.assign(this, obj);
        }
    }
}
