import { organizationsServicePurchases } from '@/main';
import SideActionBase from '@/plugin/sideActions/sideActionBase';
import { Component } from 'vue-property-decorator';

@Component
export default class OrganizationActionComponent extends SideActionBase<any> {
    public hits: any[] = [];

    public created() {
        if (this.options.hits) {
            this.hits = this.options.hits;
        }
    }

    public async useOrg(org) {
        const usedOrg = await organizationsServicePurchases.getCOCdata(org.id);
        this.finished(usedOrg);
    }
}
